<template>
  <Popup
    :title="isModify ? 'FAQ 카테고리 수정' : 'FAQ 카테고리 추가'"
    :maxWidth="600"
    closeBtnText="닫기"
    compeleteBtnText="저장"
    :isDisabeldCompeleteBtn="!categoryDataSync.name"
    @onClickClose="$emit('onClickClose')"
    @onClickComplete="$emit('onClickComplete',categoryDataSync, isModify)">
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:140px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>카테고리명<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Input
              placeholder="카테고리명"
              :value.sync="categoryDataSync.name"/>
          </td>
        </tr>
        <tr>
          <th>삭제유무<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <SwitchCheck
              :value.sync="categoryDataSync.deletable"
              text="삭제가능"
              offText="삭제불가"/>
          </td>
        </tr>
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import SwitchCheck from '@lemontree-ai/lemontree-admin-common-front/components/common/switch/SwitchCheck';

export default {
  name:'FaqCategoryRegisterPopup',
  props:{
    categoryData: Object
  },
  mounted(){
    this.setData()
  },
  components:{
    Popup,
    TableView,
    IconSvg,
    Input,
    SwitchCheck,
  },
  data(){
    return{
      isModify:false,
      categoryDataSync:{}
    }
  },
  methods:{
    setData(){
      if(this.categoryData.name){
        this.isModify = true
      }
      this.categoryDataSync = Object.assign({},this.categoryData)
    }
  }
}
</script>
<style scoped>
</style>