<template>
  <PageWithLayout>
    <p class="desc_info">💡 FAQ 카테고리 이름과 앱에서 노출되는 순서를 조정할 수 있습니다.</p>
    <GroupCategory
      :groupCategoryData.sync="viewModel.categoryData"
      :dataList="viewModel.dataList"
      :value.sync="viewModel.categoryData.value"
      @onModifyOrder="orderData => viewModel.onModifyOrder(orderData)">
      <template v-slot:item_category="slotProps">
        <span class="txt_item" :style="viewModel.categoryData.titleList[0].style">
          <IconSvg iconName="drag" :size="18" iconColor="#bbb" />
        </span>
          <!-- <Input
            v-if="slotProps.itemData.deletable"
            :value.sync="slotProps.itemData.name"
            @update:value="viewModel.onEdit()"/> -->
        <strong class="tit_item fill_item">
          {{ slotProps.itemData.name }}
        </strong>
        <span class="txt_item" :style="viewModel.categoryData.titleList[2].style">
          <Button
            btnSize="small"
            btnStyle="secondary"
            text="수정"
            @onClickBtn="viewModel.onClickEditCategoryItem(slotProps.itemData)"/>
        </span>
        <span class="txt_item" :style="viewModel.categoryData.titleList[3].style">
          <Button
            v-if="slotProps.itemData.deletable"
            btnSize="small"
            btnStyle="fourthly"
            text="삭제"
            @onClickBtn="viewModel.onClickDeleteCategoryItem(slotProps.itemData.id)"/>
          <template v-else>
            삭제불가
          </template>
        </span>
      </template>
    </GroupCategory>
    <Sticky>
      <!-- <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="되돌리기"
          :disabled="viewModel.isDisabledResetBtn"
          @onClickBtn="viewModel.getFaqCategory()">
          <IconSvg
            iconName="reset"
            :size="12"
            :iconColor="viewModel.isDisabledResetBtn ? 'DISABLED_ICON' : '#222'"
            :iconDarkColor="viewModel.isDisabledResetBtn ? 'DISABLED_ICON_DARK' : '#fff'"/>
      </Button> -->
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="secondary"
          text="추가"
          @onClickBtn="viewModel.onClickAddCategory()"/>
        <!-- <Button
          btnSize="medium"
          btnStyle="primary"
          text="저장"
          @onClickBtn="viewModel.onClickCompleteFaqCagegory()"/> -->
      </div>
    </Sticky>
    <template v-slot:popup>
      <FaqCategoryRegisterPopup
        v-if="viewModel.isFaqCategoryRegisterPopup"
        :categoryData="viewModel.faqCategoryRegisterPopupData"
        @onClickClose="viewModel.onClickFaqCategoryRegisterPopupClose()"
        @onClickComplete="(val,isModify) => viewModel.onClickFaqCategoryRegisterPopupComplete(val,isModify)"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import GroupCategory from '@lemontree-ai/lemontree-admin-common-front/components/common/category/GroupCategory'
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import FaqCategoryRegisterPopup from '@/views/service/faq/view/popup/FaqCategoryRegisterPopup';
// viewModel
import FaqCategoryViewModel from '@/views/service/faq/viewModel/FaqCategoryViewModel'

export default {
  name:'FaqCategory',
  components:{
    PageWithLayout,
    GroupCategory,
    Input,
    Sticky,
    Button,
    IconSvg,

    FaqCategoryRegisterPopup
  },
  beforeMount(){
    this.viewModel.init();
  },
  data(){
    return{
      viewModel: new FaqCategoryViewModel(),
    }
  },
}
</script>

<style lang="scss" scoped>
.desc_info + .area_category{margin-top:20px}

.area_category{padding:0;border-left:1px solid $DEFAULT_BORDER;border-right:1px solid $DEFAULT_BORDER;border-radius:$CONTENT_RADIUS}
.area_category ::v-deep .list_category li{line-height:36px}

.area_category ::v-deep .list_tit_category{padding:9px 14px}
.area_category ::v-deep .list_category li{padding:5px 14px}

.area_category ::v-deep .list_tit_category > * + *,
.area_category ::v-deep .list_category .inner_item > * + *{margin-left:12px}

.area_category ::v-deep .list_tit_category .tit_comm{font-size:14px;font-weight:600}

.area_category ::v-deep .list_category li .tf_comm{display:block;flex:1 1}
.area_category ::v-deep .list_category li .tit_item{padding:0;font-size:14px;line-height:38px}
.area_category ::v-deep .list_category li .txt_item{font-size:14px;line-height:38px}
.area_category ::v-deep .list_category li .icon_drag{margin:10px 0}
</style>